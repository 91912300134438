.chat-box-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.chat-box {
  width: 400px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}

.chat-header {
  background-color: #f0f0f0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-body {
  padding: 10px;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
}

.toggle-button {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40px;
  height: 40px;
  border: none;
  border-top-left-radius: 8px;
  background-color: #f0f0f0;
  cursor: pointer;
}
