@tailwind base;
@tailwind components;
@tailwind utilities;

.roman-number {
  list-style-type: upper-roman;
}

/* Pagination container */
.pagination {
  display: flex;
  list-style: none;
  justify-content: center;
  padding: 0;
  margin: 20px 0;
}

/* Pagination item */
.pagination li {
  margin: 0 5px;
  cursor: pointer;
}

/* Link style for pagination buttons */
.pagination li a {
  padding: 8px 12px;
  text-decoration: none;
  color: #007bff;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition:
    background-color 0.3s,
    color 0.3s;
}

/* Hover effect */
.pagination li a:hover {
  background-color: #f0f0f0;
}

/* Active page style */
.pagination .active a {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

/* Disabled previous/next buttons */
.pagination .disabled a {
  color: #ccc;
  cursor: not-allowed;
  pointer-events: none;
  background-color: #f9f9f9;
}
